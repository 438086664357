import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay])

export default function sliders() {
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.video-swiper')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					centeredSlides: true,
					slidesPerView: 2,
					spaceBetween: 60,

					breakpoints: {
						640: {
							spaceBetween: 220,
						},
						992: {
							spaceBetween: 280,
						},
						1172: {
							spaceBetween: 380,
						},
					},
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.logo-gallery-swiper')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					// autoplay: {
					// 	delay: 2000,
					// 	disableOnInteraction: true,
					// },
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					slidesPerView: 2,
					spaceBetween: 10,

					breakpoints: {
						640: {
							spaceBetween: 15,
							slidesPerView: 3,
						},
						992: {
							spaceBetween: 20,
							slidesPerView: 4,
						},
						1172: {
							spaceBetween: 32,
							slidesPerView: 5,
						},
					},
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.simple-video-swiper')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					slidesPerView: 1,
					effect: 'fade',
					autoHeight: true,

					breakpoints: {
						640: {
							spaceBetween: 15,
							slidesPerView: 2,
						},
						992: {
							spaceBetween: 20,
							slidesPerView: 2,
						},
						1172: {
							spaceBetween: 46,
							slidesPerView: 2,
						},
					},
				}

				// init slider
				var swiper = new Swiper(el, options)

				// Stop videos playing when slide changes
				swiper.on('slideChange', function () {
					let overlays = document.querySelectorAll('.overlay')
					let playBtn = document.querySelectorAll('.play-button')
					document
						.querySelectorAll('.video-container iframe')
						.forEach((el, index) => {
							el.remove()
							overlays.forEach((overlay) => {
								overlay.style.display = 'block'
							})
							playBtn.forEach((btn) => {
								btn.style.display = 'block'
							})
						})
				})
			})
		})
	})(jQuery)
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll(
				'.testimonials-gallery-swiper'
			)
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					centeredSlides: true,
					slidesPerView: 1,
					effect: 'fade',
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.reviews-swiper')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					// autoplay: {
					// 	delay: 2000,
					// 	disableOnInteraction: true,
					// },
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					slidesPerView: 1,
					spaceBetween: 10,

					breakpoints: {
						768: {
							spaceBetween: 15,
							slidesPerView: 2,
						},
						992: {
							spaceBetween: 30,
							slidesPerView: 2,
						},
					},
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)
}
